<template>
  <div id="sidebar-container" class="col-lg-3">
    <div
      class="d-lg-block"
      id="sidebar"
      :class="{ 'sidebar-custom-closed': !$store.state.mobileSidebarOpen }"
    >
      <div id="sidebar-header" class="row">
        <div class="col-12" id="sidebar-header-title">
          <p>{{ $store.state.meetingName }}</p>
        </div>
        <div class="welcomeMessage" v-if="user && user.name">
          Welcome {{ user.name }}!
        </div>
        <div
          class="row py-2"
          v-if="!hideSponsorInfo && sponsor && sponsor.IsActive"
        >
          <div class="col-lg-12 col-6 text-center" id="sidebar-sponsor-message">
            <p>MOBILE APP SPONSORED BY:</p>
          </div>
          <div
            class="col-lg-12 col-6 px-2"
            id="sidebar-sponsor-image-container"
          >
            <a :href="sponsor.Url">
              <img
                :src="sponsor.ImageUrl"
                :alt="sponsor.ImageAltText"
                id="sidebar-sponsor-image"
              />
            </a>
          </div>
          <div
            class="col-12 text-center my-2"
            v-if="sponsor && sponsor.BoothNumber"
          >
            <p class="font-weight-bold m-0">
              Visit booth #{{ sponsor.BoothNumber }}
            </p>
          </div>
        </div>
      </div>
      <div id="sidebar-body">
        <the-sidebar-navigation></the-sidebar-navigation>
      </div>
    </div>
  </div>
</template>
<script>
import TheSidebarNavigation from "@/components/TheSidebarNavigation.vue";
import { mapState } from "vuex";

export default {
  name: "TheSidebar",
  components: { TheSidebarNavigation },
  computed: {
    ...mapState(["user", "sponsor"]),
    hideSponsorInfo: function () {
      return this.$route.meta ? this.$route.meta.hideSponsorInfo : false;
    },
  },
  created() {
    let meta = this.$route.meta;
    return meta;
  },
};
</script>

<style scoped>
#sidebar {
  background: white;
  border-top: 1px solid #fff;
  width: 100%;
  position: sticky;
  top: 80px;
}
.welcomeMessage {
  border-bottom: 1px solid #dee3ec;
  padding: 10px;
  width: 100%;
  text-align: center;
}
#sidebar-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #dee3ec;
}

#sidebar-header-title {
  padding: 10px 10px;
  background: #1c7cd5;
  color: white;
  margin-bottom: 10px;
}

#sidebar-header-title p {
  margin: 0;
  font-weight: bold;
  text-align: center;
}

#sidebar-sponsor-message {
  color: #0f2940;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

#sidebar-sponsor-message p {
  font-weight: bold;
  margin: 0;
}

#sidebar-sponsor-image-container {
  border-left: 2px solid #1c7cd5;
  display: flex;
  justify-content: center;
}

#sidebar-sponsor-image {
  max-height: 200px;
  max-width: 200px;
  width: auto;
  margin: 10px auto;
  flex-shrink: 0;
}

#sidebar-body {
  padding: 0;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  #sidebar {
    position: fixed;
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    left: 0px;
    top: 65px;
    bottom: 0;
    z-index: 2;
    overflow-y: scroll;
    border-bottom: 1px solid #dee3de;
    box-shadow: 2px 2px 20px -5px #c1cbdc, -2px 2px 20px -5px #c1cbdc;
  }

  #sidebar.sidebar-custom-closed {
    position: fixed;
    left: -1000px;
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    z-index: 2;
  }
}

@media (min-width: 991.98px) {
  #sidebar-sponsor-image-container {
    border-left: none;
  }

  #sidebar-sponsor-image {
    max-height: 100px;
    max-width: 200px;
    width: auto;
    margin: 10px auto;
  }

  #sidebar {
    border-right: 1px solid #dee3ec;
  }
}
</style>
